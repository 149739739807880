import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Team from "../components/team";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `spectatin about`,
          `event-based analytics`,
          `attribution modeling`,
          `tracking plan`
        ]}
        title="About"
      />

      <section className="flex flex-col mx-auto py-12 bg-white max-w-4xl">
        <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase px-4 sm:px-6 lg:px-8">
          About
        </p>
        <div className="px-4 sm:px-6 lg:px-8">
          <p className="my-4 text-xl leading-7 text-gray-500 ">
            Throughout my career as an engineer, I&apos;ve always put the user
            experience first.
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            While at PayPal years back, I fell in love with improving the user
            experience through analytics. I became an analytics champion
            on my team and enjoyed every minute of it. I began to deeply analyze
            metrics and business outcomes and saw how closely tied they were.
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            Then I created Spectatin to help folks with analytics. Through
            building tools and working with founders and marketers, I&apos;m
            able to further my mission to make the web a better experience for
            everyone.
          </p>
          {/* <p className="my-4 text-xl leading-7 text-gray-500">
            Performance has always been a challenging and interesting problem.
            From understanding how the web works to performance diagnoses on
            front-end JavaScript frameworks, server (re)architecture, image
            optimization, and especially to design. I enjoy diving into the
            breadth of topics and sharing knowledge.
          </p> */}
          {/* <p className="my-4 text-xl leading-7 text-gray-500">
            Most recently, Google announced Core Web Vitals and the stance
            they&apos;re taking. Get fast. Stay fast. It&apos;s critical to
            users and all site owners.
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            Through Core Web Vitals, Google defined specific metrics and
            thresholds (which evolve yearly) to represent the user experience.
            And the thresholds Google has chosen are TOUGH to beat. Specifically
            LCP on mobile. And especially if (when) Google makes the thresholds
            more challenging to pass or raises the percentile from 75th to 80th
            and beyond. (We measured at the 95th percentile when I was at
            PayPal, and let me tell you, it&apos;s not easy to build a
            performant site on slow mobile devices with slow connections.)
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            Google also stated that Core Web Vitals will be used as part of
            Google&apos;s SEO ranking algorithm starting sometime in 2021.
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            Passing Core Web Vitals is going to be a big problem for nearly
            every site and a lot of site owners care a lot about SEO. Approaches
            to passing Core Web Vitals can be extremely costly and can require
            tons of time. Entire architectural rewrites are going to be needed
            for many sites that want to pass, and those sites need to get
            started now. Some sites will be able to pass Core Web Vitals through
            optimizations. Or site owners can arm themselves with the right
            knowledge and right experts, and passing Core Web Vitals can be as
            simple as some low-hanging fruit or a small design change.
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            Some site owners will arm themselves with the right knowledge and
            right experts to pass Core Web Vitals and stay well under the pass
            threshold. Passing Core Web Vitals can be as simple as some
            low-hanging fruit or a small design change - although not always.
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            I predict that, for at least the next couple years, passing Core Web
            Vitals will be an awesome SEO differentiator (and that&apos;s
            totally ignoring the massive bounce rate improvements a faster site
            brings). The performance conversation will go upstream to marketing
            and design. Then, in a few years, passing Core Web Vitals will
            become table stakes. Companies will have awesome performance
            cultures. Yet Core Web Vitals will be continually more challenging
            to pass as they evolve. Knowing how to get fast and stay fast will
            be more important than ever before.
          </p>
          <p className="my-4 text-xl leading-7 text-gray-500">
            It&apos;s a big problem and I want to help.
          </p> */}
        </div>
        <Team />
      </section>
    </Layout>
  );
}

export default AboutPage;
